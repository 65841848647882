import React, {Fragment} from 'react'
import Header from "../components/Header";
import ContactPage from "../templates/Contact-ayudamos";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import SectionTitle from '../components/UI/SectionTitle/index'
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import BlogDetails from '../templates/BlogDetails/ContentWrapper'
const avisoDePrivacidad = () => {
  return (
    <div>
            <Fragment>
            <Header/>
            <div className="container" style={{marginBottom:'60px', marginTop:'60px'}}>
              <SectionTitle title="" heading="Aviso de privacidad" />
              <p >Banco de Alimentos de Mérida, Asociación Civil (LA ASOCIACIÓN) con domicilio
               en calle 60 entre 69 y 71 numero 427colonia Mil Piedras en Mérida Yucatan código 
               postal 97370 informa por medio del presente Aviso de Privacidad a los titulares de
                los datos personales (LOS TITULARES), que es la responsable del tratamiento
                 legítimo y debidamente controlado de la información (DATOS PERSONALES) recabada
                  a LOS TITULARES, con las finalidades de poder brindarles los beneficios de los
                   programas de LA ASOCIACIÓN, contactarlos en caso de así ser necesario, así como
                    para control interno, estadístico y para el estricto uso de la relación entre
                     los titulares y LA ASOCIACIÓN. Una vez recabados los DATOS PERSONALES por LA
                      ASOCIACIÓN, ésta será la responsable de custodiarlos, así como de destinarlos
                       uso único y exclusivo de los fines establecidos en el presente aviso, 
                       informando a LOS TITULARES que podrán ejercer su derecho de acceso, 
                       rectificación, cancelación u oposición de LOS DATOS PERSONALES, acudiendo de 
                       manera personal a la Coordinación de Atención a Beneficiarios, en el domicilio
                        de LA ASOCIACIÓN, el cual ya se encuentra indicado en el inicio del presente 
                        aviso de privacidad. Así mismo, LOS DATOS PERSONALES podrán ser transferidos 
                        por LA ASOCIACIÓN responsable sin el consentimiento de LOS TITULARES, cuando 
                        se presente alguno de los supuestos establecidos en el artículo 37 de la Ley 
                        Federal de Protección de Datos Personales en Posesión de los Particulares. 
                        Para el supuesto de que LA ASOCIACIÓN realizara cambios al presente Aviso de 
                        Privacidad, se comunicará a LOS TITULARES a través del sitio de internet 
                        www.bamac.com.mx. El presente aviso se realiza en estricto cumplimiento a lo 
                        establecido en la citada Ley Federal de Protección de Datos Personales en 
                        Posesión de los Particulares</p>
            </div>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    </div>
  )
}

export default avisoDePrivacidad
